import '~/scripts/integrations/jquery-extended';

export default class MeetWithUs {
  constructor() {
    this.urlParams = '';
    this.addEvents();
  }

  addEvents() {
    $('#openAlertBtn').on('click', () => this.largeCompanyAlert(250));
  }

  askQuestionAlert(employeeSize) {
    const defaultSwalOpts = {
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      showConfirmButton: false
    };
    const url = `/sales-question?size=${employeeSize} person company`;
    Swal.fire({
      title: 'Ask Us a Question',
      html: '<img src="/assets/icons/ajax-loader-medium.gif">',
      didOpen: (swalEl) => {
        $.get(url, (data) => {
          const $swalEl = $(swalEl);
          $swalEl.find('.swal2-html-container').html(data);

          const recaptchaWrapper = $swalEl.find('.g-recaptcha');
          if (recaptchaWrapper.length) {
            grecaptcha.render(recaptchaWrapper[0]);
          }

          $swalEl.find('.captcha-trigger-button').on(R.touchEvent, (e) => {
            R.captchaInstance.attachCaptchaEvents(e);
          });

          const $formInDom = $swalEl.find('form');
          $formInDom.on('ajax:success', (evt, data, status, xhr) => {
            const responseUrl = data.location || xhr.getResponseHeader('Location');
            if (responseUrl) {
              Swal.close();
              Swal.fire({
                html: `<iframe src="${responseUrl}" class="w-100 p-2 pb-0" style="height:270px"></iframe>`,
                ...defaultSwalOpts
              });
            }
          });
        });
      },
      ...defaultSwalOpts
    });
  }

  largeCompanyAlert(size) {
    this.urlParams += `&UTM_COUNT=${size}`;
    this.iFrameURL = `https://recognize-enterprise.youcanbook.me?${this.urlParams}`;

    Swal.fire({
      html: `
        <img src="/assets/icons/ajax-loader-medium.gif" id="scheduleDemoiFrameLoader">
        <iframe class="scheduleDemoiFrame" src="${this.iFrameURL}" 
          style="width: ${window.outerWidth - 100}px; height: ${window.outerHeight - 100}px;" 
          onload="document.getElementById('scheduleDemoiFrameLoader').style.display='none';">
        </iframe>
      `,
      grow: 'fullscreen',
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.close && window.localStorage.getItem('bookedDemo') !== 'true') {
        this.askQuestionAlert(size);
      }
    });
  }
}

new MeetWithUs();
